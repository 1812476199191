import React, { useState, useEffect } from "react";

import "./css/shop.css";
import { _fetch } from "./_fetch";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Modal, Card, message, Button, Form, Input, Table, Space } from "antd";
import useRazorpay from "react-razorpay";
import noimage from "./assets/OIP (1).jpeg";
const { Meta } = Card;
export default function Shop() {
  const navigate = useNavigate();

  let apiurl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT
      : process.env.REACT_APP_API;
  const [Razorpay] = useRazorpay();
  const [cookie] = useCookies(["gsa-user"]);
  const [shippingAddress, setShippingAddress] = useState();
  const [COD, setCOD] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState();
  const [cartitems, setCartItems] = useState([]);

  const columns = [
    {
      title: "Product Image",
      dataIndex: "logo",
      key: "logo",
      render: (data) => {
        return (
          <img
            src={`${process.env.REACT_APP_AWS_S3_URL}/${data.path}`}
            style={{ height: "40px" }}
          />
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (_, record) => (
        <p>{record.discount ? record.discount : record.price}</p>
      ),
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
    },

    {
      title: "Action",
      key: "action",
      render: (_, record, ind) => (
        <Space size="middle">
          <Button onClick={() => decreaseCount(record?._id, ind)}>-</Button>
          <span style={{ margin: "0 10px", fontSize: "1.2em" }}>
            {record.totalcount}
          </span>
          <Button onClick={() => increaseCount(record?._id, ind)}>+</Button>
        </Space>
      ),
    },
  ];

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [finalprice, setFinalPrice] = useState(0);
  const increaseCount = async (productID, ind) => {
    if (!user) {
      message.error("Please login");
      navigate("/login");
    }
    let cartorder = await _fetch(`${apiurl}/api/v1/user/${user?._id}`, {
      method: "PATCH",
      body: {
        cart: [...user?.cart, productID],
      },
    });
    cartorder = await cartorder.json();
    getData();
    window.location.reload();
  };
  const placeOrder = async () => {
    try {
      let neworder = await _fetch(
        `${apiurl}/api/v1/user/${user?._id}/cart/order`,
        {
          method: "POST",
        }
      );
      neworder = await neworder.json();
      if (neworder.success) {
        const options = {
          description: "Credits towards consultation",
          currency: "INR",
          order_id: neworder?.response.order_id,
          key: process.env.RAZORPAY_KEY_ID,
          amount: neworder?.response?.amount,
          external: {
            wallets: ["paytm"],
          },
          name: user?.first_name,
          prefill: {
            email: user?.email,
            name: `${user?.first_name} ${user?.last_name}`,
          },
          theme: { color: "#F37254" },
          handler: async function (data) {
            let response = await _fetch(
              `${apiurl}/api/v1/user/${user?._id}/cart/verifypayment`,
              {
                method: "POST",
                body: {
                  razorpay_payment_id: data?.razorpay_payment_id,
                  order_id: data?.razorpay_order_id,
                  razorpay_signature: data?.razorpay_signature,
                },
              }
            );
            response = await response.json();
            console.log(neworder);
            if (response.success) {
              console.log(neworder);
              let res = await _fetch(
                `${apiurl}/api/v1/shiprocket/createorder`,
                {
                  method: "POST",
                  body: {
                    id: neworder?.response?.ordercreated?._id,
                    cod: false,
                  },
                }
              );
              res = await res.json();
              await _fetch(`${apiurl}/api/v1/user/${user?._id}`, {
                method: "PATCH",
                body: {
                  cart: [],
                },
              });
              message.success("Payment successful");
              window.location.reload();
            } else {
              window.location.reload();
            }
          },
        };

        const rz = new Razorpay(options);
        rz.on("payment.failed", function (response) {
          message.error("Payment failed");
        });
        rz.open();
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  const onFinish = async (values) => {
    try {
      let res = await _fetch(`${apiurl}/api/v1/user/${user._id}`, {
        method: "PATCH",
        body: values,
      });
      res = await res.json();
      if (res.success) {
        let shippingaddrss =
          values["shipping_address.street"] +
          " " +
          values["shipping_address.state"] +
          " " +
          values["shipping_address.pincode"];
        setShippingAddress(shippingaddrss);
        message.success("Address Updated");
        setIsModalOpen(false);
        if (COD) {
          setCOD(false);
          let res = await _fetch(
            `${apiurl}/api/v1/user/${user?._id}/cart/order`,
            {
              method: "POST",
            }
          );
          res = await res.json();
          res = await _fetch(`${apiurl}/api/v1/shiprocket/createorder`, {
            method: "POST",
            body: { order_id: res?.response._id, cod: true },
          });
          res = await res.json();
          if (res.success) {
            message.success("Order Has Been Successfully Placed");
          } else {
            message.error("We Couldn't Place Your Order.Please Try again");
          }
          return;
        }
        placeOrder();
      } else {
        message.error("Please Try Again");
      }
    } catch (err) {
      message.error("Please Try Again");
    }
  };
  const decreaseCount = async (productID, ind) => {
    if (!user) {
      message.error("Please login");
      navigate("/login");
      return;
    }
    let newcart = user?.cart;
    let indexToRemove1 = newcart.indexOf(productID.toString());
    newcart.splice(indexToRemove1, 1);
    if (cartitems[ind].totalcount > 0) {
      let cartorder = await _fetch(`${apiurl}/api/v1/user/${user?._id}`, {
        method: "PATCH",
        body: {
          cart: newcart,
        },
      });
      cartorder = await cartorder.json();
      if (cartorder.success) {
        getData();
        window.location.reload();
      }
    }
  };

  async function getData() {
    try {
      let userid = cookie["crafti&%373loggin"]?._id;
      if (!cookie["crafti&%373loggin"]?._id) {
        navigate("/login");
      }
      let res = await _fetch(`${apiurl}/api/v1/user/${userid}`);
      res = await res.json();
      if (res.success) {
        if (
          res.response?.shipping_address?.pincode &&
          res.response?.shipping_address?.state &&
          res.response?.shipping_address?.street
        ) {
          let shippingaddrss =
            res.response.shipping_address.street +
            " " +
            res.response.shipping_address.state +
            " " +
            res.response.shipping_address.pincode;
          setShippingAddress(shippingaddrss);
        } else if (
          res.response?.address?.pincode &&
          res.response?.address?.state &&
          res.response?.address?.street
        ) {
          let shippingaddrss =
            res.response?.address?.street +
            " " +
            res.response?.address?.state +
            " " +
            res.response?.address?.pincode;
          setShippingAddress(shippingaddrss);
        }
        setUser(res.response);
      }
      const cartitem = new Map();
      let cost = 0;
      let finalcart = await Promise.all(
        res?.response?.cart?.map(async (data, ind) => {
          if (cartitem.get(data._id.toString())) {
            let obj = cartitem.get(data._id.toString());
            let totalcount = obj.totalcount + 1;
            obj.totalcount = totalcount;
            cartitem.set(data._id.toString(), obj);
          } else {
            let totalcount = 1;
            cartitem.set(data._id.toString(), { ...data, totalcount });
          }
          let photo = await _fetch(`${apiurl}/api/v1/photo/filter`, {
            method: "POST",
            body: { variant_id: data._id },
          });
          let productprice = data?.price ? data?.price : 0;
          let discount = data?.discount ? data?.discount : data?.price;
          cost += discount;
          photo = await photo.json();

          return photo.response[0]?.path;
        })
      );
      setFinalPrice(cost);
      res.response?.cart?.map((data, ind) => {
        if (cartitem.get(data._id.toString())) {
          let obj = cartitem.get(data._id.toString());
          let image = finalcart[ind];
          obj.image = image;
          cartitem.set(data._id.toString(), obj);
        }
      });
      let cartitemuniq = [];
      for (const [key, value] of cartitem.entries()) {
        cartitemuniq.push(value);
      }
      setCartItems(cartitemuniq);
    } catch (err) {
      console.error(err);
    }
  }

  async function handlePayment(prepaidpayment) {
    if (!user) {
      message.error("Please login to Buy product");
      navigate("/login");
      return;
    }
    if (!prepaidpayment) {
      setCOD(true);
      setIsModalOpen(true);
      return;
    }
    setIsModalOpen(true);
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        maxWidth: "100%",
        overflow: "hidden",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "70%",
          gap: "30px",
          justifyContent: "center",
          overflowX: "auto",
          flexWrap: "wrap",
          margin: "10px",
        }}
      >
        {/* {cartitems.length ? (
          cartitems?.map((data, ind) => {
            let imgsrc = data?.logo?.path;
            if (!imgsrc) {
              imgsrc = noimage;
            }

            return (
              <div>
                <Card
                  style={{
                    width: 200,
                  }}
                  cover={
                    <img
                      alt="Product Image"
                      src={`${process.env.REACT_APP_AWS_S3_URL}/${imgsrc}`}
                    />
                  }
                >
                  <div>
                    <p>{data?.name}</p>
                    <span>price</span>
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                    >
                      ₹{data?.discount ? data?.discount : data?.price}
                    </span>
                    <span
                      style={{
                        color: "#919191",
                        fontWeight: "500",
                        textTransform: "uppercase",
                        fontSize: "15px",
                        textDecoration: "line-through",
                        paddingLeft: "10px",
                      }}
                    >
                      ₹{data.price ? data.price : 0}
                    </span>
                    <div style={{ marginTop: "5px" }}>
                      <Button onClick={() => decreaseCount(data?._id, ind)}>
                        -
                      </Button>
                      <span style={{ margin: "0 10px", fontSize: "1.2em" }}>
                        {data.totalcount}
                      </span>
                      <Button onClick={() => increaseCount(data?._id, ind)}>
                        +
                      </Button>
                    </div>
                  </div>
                </Card>
              </div>
            );
          })
        ) : (
          <h1>No Item Found</h1>
        )} */}
        {cartitems.length > 0 ? (
          <Table
            columns={columns}
            dataSource={cartitems}
            style={{ width: "100%" }}
          ></Table>
        ) : (
          <h1>No Item Found</h1>
        )}
      </div>
      {/* {cartitems.length > 0 ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Card
            title="Order Details"
            style={{
              width: 300,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "4px",
                width: "100%",
              }}
            >
              <div style={{ width: "40px" }}>
                <h4>Shipping to:</h4>
              </div>
              <div>
                {shippingAddress ? (
                  <p>{shippingAddress}</p>
                ) : user?.address?.street &&
                  user?.address?.state &&
                  user?.address?.pincode ? (
                  <p>
                    {`${user.address.street} ${user.address.state} ${user.address.pincode}`}
                  </p>
                ) : (
                  <div onClick={showModal} style={{ color: "blue" }}>
                    Add Address
                  </div>
                )}
              </div>
            </div>
            {(user?.address?.street &&
              user?.address?.state &&
              user?.address?.pincode) ||
            (user?.shipping_address?.street &&
              user?.shipping_address?.state &&
              user?.shipping_address?.pincode) ? (
              <Button type="link" onClick={showModal}>
                Change Address
              </Button>
            ) : (
              ""
            )}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Subtotal</p>
              <p> ₹{finalprice}</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button type="primary" onClick={() => handlePayment()}>
                Proceed To Buy
              </Button>
            </div>
          </Card>
        </div>
      ) : (
        ""
      )} */}
      {cartitems.length > 0 ? (
        <div style={{ width: "70%", margin: "10px" }}>
          <h3>CART TOTALS</h3>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ width: "40%", fontSize: "15px" }}>
                <p>Subtotal</p>
              </div>
              <div style={{ width: "40%" }}>
                <p>₹{finalprice}</p>
              </div>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ width: "40%", fontSize: "15px" }}>
                <p>Shipping</p>
              </div>
              <div style={{ width: "40%" }}>
                <div>
                  {shippingAddress ? (
                    <p>Shipping To: {shippingAddress}</p>
                  ) : user?.address?.street &&
                    user?.address?.state &&
                    user?.address?.pincode ? (
                    <p>
                      Shipping To:{" "}
                      {`${user.address.street} ${user.address.state} ${user.address.pincode}`}
                    </p>
                  ) : (
                    <div onClick={showModal} style={{ color: "blue" }}>
                      Add Address
                    </div>
                  )}
                </div>
                {/* {(user?.address?.street &&
                  user?.address?.state &&
                  user?.address?.pincode) ||
                (user?.shipping_address?.street &&
                  user?.shipping_address?.state &&
                  user?.shipping_address?.pincode) ? (
                  <Button type="link" onClick={showModal}>
                    Change Address
                  </Button>
                ) : (
                  ""
                )} */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "15px",
                justifyContent: "start",
                paddingLeft: "15px",
              }}
            >
              <Button
                type="primary"
                onClick={() => handlePayment(true)}
                style={{ width: "20%" }}
              >
                Proceed To Buy
              </Button>
              {/* <Button
                type="primary"
                onClick={() => handlePayment(false)}
                style={{ width: "20%" }}
              >
                Cash On Delivery
              </Button> */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Modal
        title="Ship To"
        open={isModalOpen}
        onOk={false}
        destroyOnClose
        footer={[
          <Button type="primary" htmlType="submit" form="createForm" block>
            Submit
          </Button>,
        ]}
        onCancel={handleCancel}
      >
        <Form
          id="createForm"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Mobile"
            name="shipping_address.mobile"
            rules={[
              {
                required: true,
                message: "Please input Mobile Number",
              },
              {
                pattern: /^\d{10}$/,
                message: "Mobile number must be exactly 10 digits",
              },
            ]}
          >
            <Input type="number" maxLength={10} />
          </Form.Item>
          <Form.Item
            label="State"
            name="shipping_address.state"
            rules={[
              {
                required: true,
                message: "Please input State",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="City"
            name="shipping_address.city"
            rules={[
              {
                required: true,
                message: "Please input State",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Address/Flat No:"
            name="shipping_address.street"
            rules={[
              {
                required: true,
                message: "Please input Street",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Pincode"
            name="shipping_address.pincode"
            rules={[
              {
                required: true,
                message: "Please input pincode",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
