import React, { useState } from "react";

import { Button, Checkbox, Flex, Form, Input, message } from "antd";
import { _fetch } from "./_fetch";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "./firebase/setup";
const onFinishFailed = (errorInfo) => {
  console.error("Failed:", errorInfo);
};
const UserForm = () => {
  let apiurl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT
      : process.env.REACT_APP_API;
  const [cookies, setCookie] = useCookies(["gsa-user"]);
  const navigate = useNavigate();
  const [logInByOtp, setLoginByOtp] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [phone, setPhone] = useState();
  const [otp, setOtp] = useState();
  const [userverifybyotp, setUserVerifyByOtp] = useState(null);
  const [loading, setLoading] = useState(false);
  const sendOtpToCustomer = async (value) => {
    try {
      setLoading(true);
      let res = await _fetch(`${apiurl}/api/v1/user/filter`, {
        method: "POST",
        body: { loginByOtp: true, phone: phone },
      });
      res = await res.json();
      if (!res.success) {
        return message.error("Please sign up to continue");
      }
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {});
      if (!recaptcha) {
        return message.error("Too Many Attempts");
      }
      setLoading(false);
      const confirmation = await signInWithPhoneNumber(
        auth,
        "+" + phone,
        recaptcha
      );
      setUserVerifyByOtp(confirmation);
      setSendOtp(true);
      setLoading(false);
    } catch (err) {
      message.error(err.message);
      setLoading(false);
    }
  };
  const verifyOtp = async (value) => {
    try {
      let x = await userverifybyotp.confirm(otp);
      let res = await _fetch(`${apiurl}/api/v1/user/login`, {
        method: "POST",
        body: { loginByOtp: true, phone: phone },
      });
      res = await res.json();
      if (res.success) {
        setCookie("crafti&%373loggin", res.response, { path: "/" });
        message.success("User LoggedIn");
        navigate("/");
      } else {
        message.error("Something Went Wrong");
      }
    } catch (err) {
      console.error(err);
    }
  };
  const onFinish = async (values) => {
    try {
      let res = await _fetch(`${apiurl}/api/v1/user/login`, {
        method: "POST",
        body: values,
      });
      res = await res.json();
      if (res.success) {
        setCookie("crafti&%373loggin", res.response, { path: "/" });
        message.success("User LoggedIn");
        navigate("/");
      } else {
        message.error("Something Went Wrong");
      }
    } catch (err) {
      message.error("Something Went Wrong");
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        minHeight: "79vh",
      }}
    >
      {!logInByOtp ? (
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          style={{ width: "50%" }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
            style={{ width: "100%" }}
          >
            <Button type="primary" htmlType="submit">
              Log In
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: "10px" }}
              onClick={() => navigate("/user/add")}
            >
              Sign Up
            </Button>
          </Form.Item>

          {/* <a
            type="primary"
            onClick={() => setLoginByOtp(true)}
            style={{ marginLeft: "10px" }}
          >
            Send OTP
          </a> */}
        </Form>
      ) : (
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          style={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          // onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="Phone" name="phone">
            <PhoneInput
              country={"in"}
              value={phone}
              onChange={(e) => setPhone(e)}
            />
          </Form.Item>
          {sendOtp ? (
            <Form.Item
              label="Otp"
              name="otp"
              rules={[
                {
                  required: true,
                  message: "Please input your otp!",
                },
              ]}
              style={{ width: "200px" }}
            >
              <Input
                onChange={(e) => {
                  setOtp(e?.target?.value);
                }}
              />
            </Form.Item>
          ) : (
            ""
          )}
          {!sendOtp ? (
            <>
              <Form.Item>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => sendOtpToCustomer()}
                >
                  Send Otp
                </Button>
                <a
                  type="primary"
                  onClick={() => setLoginByOtp(false)}
                  style={{ marginLeft: "10px" }}
                >
                  Login By Password
                </a>
                <a
                  type="primary"
                  onClick={() => navigate("/user/add")}
                  style={{ marginLeft: "10px" }}
                >
                  Sign Up
                </a>
              </Form.Item>
            </>
          ) : (
            <Form.Item>
              <Button type="primary" onClick={() => verifyOtp()}>
                Verify
              </Button>
            </Form.Item>
          )}
          <div id="recaptcha"></div>
        </Form>
      )}
    </div>
  );
};
export default UserForm;
