import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyBKi3KZq5tTukdDcdqIRoHwahhYDs7etrc",
  authDomain: "craftyheaven-adb30.firebaseapp.com",
  projectId: "craftyheaven-adb30",
  storageBucket: "craftyheaven-adb30.appspot.com",
  messagingSenderId: "1057890243411",
  appId: "1:1057890243411:web:fdc67a454746a7aab887a6",
};
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
