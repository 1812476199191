import React, { useState, useEffect, useRef } from "react";
import { AiFillStar } from "react-icons/ai";
import { Carousel, Image, List } from "antd";

import {
  Row,
  Col,
  Typography,
  Form,
  Input,
  InputNumber,
  Upload,
  Modal,
  Button,
  message,
  Rate,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import cloudinary from "cloudinary-core";
import "./css/shop.css";
import { FaStar, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { _fetch } from "./_fetch";
import ImageCard from "./components/Card.js";
import { Checkbox } from "antd";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { useCookies } from "react-cookie";
import axios from "axios";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Space } from "antd";
import { useNavigate } from "react-router-dom";
import useRazorpay from "react-razorpay";
import { handleCod } from "./functions/Cod.js";
export default function Shop() {
  let apiurl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT
      : process.env.REACT_APP_API;
  const [Razorpay] = useRazorpay();
  const navigate = useNavigate();
  const [cookie] = useCookies(["gsa-user"]);
  const userid = cookie["crafti&%373loggin"]?._id;
  const [product, setProduct] = useState([]);
  const [productID, setProductID] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [shippingAddress, setShippingAddress] = useState();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [currentImage, setCurrentImage] = useState();
  const [productreview, setProductReview] = useState([]);
  const [relatedProduct, setRelatedProduct] = useState([]);
  const [bannerImage, setBannerImage] = useState([]);
  const [prodrating, setProdRating] = useState();
  const [user, setUser] = useState();
  const [addtocart, setAddToCart] = useState(true);
  const [COD, setCOD] = useState(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef(null);
  const handleCarouselChange = (current) => {
    setCurrentSlide(current);
  };

  const handleImageClick = (index) => {
    setCurrentSlide(index);
    if (carouselRef.current) {
      carouselRef.current.goTo(index);
    }
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="customprev" onClick={onClick}>
        <FaChevronLeft size={25} color={"black"} />
      </div>
    );
  }
  function NextArrow(props) {
    const { onClick } = props;
    return (
      <div className="customnext" onClick={onClick}>
        <FaChevronRight size={25} color={"black"} />
      </div>
    );
  }
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const { slug } = useParams();
  const onFinish = async (values) => {
    if (!prodrating) {
      return message.error("Please Provide Rating");
    }
    if (!cookie["crafti&%373loggin"]?._id) {
      return message.error("Please Login to Add Review");
    }
    let cloud = await Promise.all(
      fileList.map(async (data, ind) => {
        const formData = new FormData();
        formData.append("file", data.originFileObj);
        formData.append("upload_preset", "afmuk3kk"); // Replace with your Cloudinary upload preset name
        let response = axios.post(
          "https://api.cloudinary.com/v1_1/dhzouknuj/image/upload",
          formData
        );
        return response;
      })
    );
    let files = await Promise.all(
      cloud.map(async (data, ind) => {
        let file = await _fetch(`${apiurl}/api/v1/photo/create`, {
          method: "POST",
          body: {
            path: data.data.url,
            name: fileList[ind].originFileObj.name,
          },
        });
        file = await file.json();
        return file.response._id;
      })
    );
    if (
      product?.user_bought === null ||
      !product?.user_bought ||
      product?.user_bought?.length === 0 ||
      (product?.user_bought?.length > 0 &&
        !product?.user_bought?.includes(userid?.toString()))
    ) {
      return message.error(
        "Please Buy this product to give us your valuable Feedback"
      );
    }
    await _fetch(`${apiurl}/api/v1/product/${productID}`, {
      method: "PATCH",
      body: { rating: values.rating },
    });
    let rating = await _fetch(`${apiurl}/api/v1/rating/create`, {
      method: "POST",
      body: {
        ...values,
        images: files,
        user_id: cookie["crafti&%373loggin"]?._id,
        product_id: productID,
      },
    });
    rating = await rating.json();
    setProductReview([...productreview, rating.response]);
    message.success("Review Added");
  };
  const placeOrder = async () => {
    try {
      let neworder = await _fetch(
        `${apiurl}/api/v1/user/${user?._id}/cart/order`,
        {
          method: "POST",
          body: {
            selectedsingleproduct: productID,
          },
        }
      );
      neworder = await neworder.json();
      if (neworder.success) {
        const options = {
          description: "Credits towards consultation",
          currency: "INR",
          order_id: neworder?.response.order_id,
          key: process.env.RAZORPAY_KEY_ID,
          amount: neworder?.response?.amount,
          external: {
            wallets: ["paytm"],
          },
          name: user.first_name,
          prefill: {
            email: user?.email,
            name: `${user?.first_name} ${user?.last_name}`,
          },
          theme: { color: "#F37254" },
          handler: async function (data) {
            let response = await _fetch(
              `${apiurl}/api/v1/user/${user?._id}/cart/verifypayment`,
              {
                method: "POST",
                body: {
                  razorpay_payment_id: data?.razorpay_payment_id,
                  order_id: data?.razorpay_order_id,
                  razorpay_signature: data?.razorpay_signature,
                },
              }
            );
            response = await response.json();
            if (response.success) {
              let res = await _fetch(
                `${apiurl}/api/v1/shiprocket/createorder`,
                {
                  method: "POST",
                  body: {
                    id: neworder?.response?.ordercreated?._id,
                    cod: false,
                  },
                }
              );
              res = await res.json();
              await _fetch(`${apiurl}/api/v1/product/${productID}`, {
                method: "PATCH",
                body: {
                  user_bought: userid,
                },
              });
              message.success("Payment successful");
            } else {
              message.error("Payment Unsuccessfull");
            }
          },
        };
        const rz = new Razorpay(options);
        rz.on("payment.failed", function (response) {
          message.error("Payment failed");
        });
        rz.open();
      }
    } catch (err) {
      console.error(err);
    }
  };
  const onChangeAddress = async (values) => {
    try {
      let res = await _fetch(`${apiurl}/api/v1/user/${user?._id}`, {
        method: "PATCH",
        body: values,
      });
      res = await res.json();
      if (res.success) {
        let shippingaddrss =
          values["shipping_address.street"] +
          " " +
          values["shipping_address.state"] +
          " " +
          values["shipping_address.pincode"];
        setShippingAddress(shippingaddrss);
        message.success("Address Updated");
        setIsModalOpen(false);
        console.log(COD);
        if (COD) {
          setCOD(false);
          let neworder = await _fetch(
            `${apiurl}/api/v1/user/${user?._id}/cart/order`,
            {
              method: "POST",
              body: {
                selectedsingleproduct: productID,
              },
            }
          );
          neworder = await neworder.json();
          neworder = await _fetch(`${apiurl}/api/v1/shiprocket/createorder`, {
            method: "POST",
            body: { id: neworder?.response?._id, cod: true },
          });
          neworder = await neworder.json();
          if (neworder?.success) {
            message.success("Order Has Been Successfully Placed");
          } else {
            message.error("We Couldn't Place Your Order.Please Try again");
          }
          return;
        }
        placeOrder();
      } else {
        message.error("Please Try Again");
      }
    } catch (err) {
      console.error(err);
      message.error("Please Try Again");
    }
  };
  async function getData() {
    try {
      let res = await _fetch(`${apiurl}/api/v1/product/filter`, {
        method: "POST",
        body: { slug },
      });
      res = await res.json();
      setProduct(res.response[0]);
      setProductID(res.response[0]._id);

      let user = await _fetch(`${apiurl}/api/v1/user/${userid}`);
      user = await user.json();
      if (user.success) {
        if (
          user.response?.shipping_address?.pincode &&
          user.response?.shipping_address?.state &&
          user.response?.shipping_address?.street
        ) {
          let shippingaddrss =
            user.response.shipping_address.street +
            " " +
            user.response.shipping_address.state +
            " " +
            user.response.shipping_address.pincode;
          setShippingAddress(shippingaddrss);
        } else if (
          res.response?.address?.pincode &&
          res.response?.address?.state &&
          res.response?.address?.street
        ) {
          let shippingaddrss =
            res.response?.address?.street +
            " " +
            res.response?.address?.state +
            " " +
            res.response?.address?.pincode;
          setShippingAddress(shippingaddrss);
        }
        setUser(user.response);
      }

      if (
        user.response?.cart?.map((data) => {
          if (data?._id?.toString() === res.response[0]._id) {
            setAddToCart(false);
          }
        })
      );

      let review = await _fetch(`${apiurl}/api/v1/rating/filter`, {
        method: "POST",
        body: { product_id: res.response[0]._id },
      });
      review = await review.json();
      setProductReview(review.response);
      let productid = res.response[0]._id;
      let tags = res.response[0].tags;
      let category_id = res.response[0].category_id;
      let sub_category_id = res.response[0].sub_category_id;
      let filtres = await _fetch(`${apiurl}/api/v1/product/filter`, {
        method: "POST",
        body: { tags, category_id, sub_category_id },
      });
      filtres = await filtres.json();
      if (filtres.success) setRelatedProduct(filtres.response);
      res = await _fetch(`${apiurl}/api/v1/photo/filter`, {
        method: "POST",
        body: { variant_id: productid },
      });
      res = await res.json();
      if (res.success) {
        setBannerImage(res.response);
        setCurrentImage(res.response[0]?.path);
      }
    } catch (err) {
      console.error(err);
    }
  }
  function getStars(rating) {
    let arr = [];
    for (let i = 0; i < rating; i++) {
      arr.push(<AiFillStar style={{ color: "#ffc107" }} />);
    }

    for (let i = 0; i < 5 - rating; i++) {
      arr.push(<AiFillStar />);
    }

    return arr;
  }
  async function handleAddToCart(useraddtocart) {
    try {
      let userid = cookie["crafti&%373loggin"]?._id;
      if (!cookie["crafti&%373loggin"]?._id) {
        navigate("/login");
      }
      if (useraddtocart) {
        let cartorder = await _fetch(`${apiurl}/api/v1/user/${userid}`, {
          method: "PATCH",
          body: {
            cart: [...user?.cart, productID],
          },
        });
        cartorder = await cartorder.json();
        if (cartorder?.success) {
          setAddToCart(false);
          message.success("Product added to cart");
          window.location.reload();
        }
      } else {
        let newcart = user?.cart?.filter(
          (data) => data.toString() === productID.toString()
        );
        let cartorder = await _fetch(`${apiurl}/api/v1/user/${userid}`, {
          method: "PATCH",
          body: {
            cart: newcart,
          },
        });
        cartorder = await cartorder.json();
        if (cartorder?.success) {
          setAddToCart(true);
          window.location.reload();
          message.error("Product removed from cart");
        }
      }
    } catch (err) {
      console.error(err);
    }
  }
  async function handlePayment(prepaidpayment) {
    try {
      if (!user) {
        message.error("Please login to Buy product");
        navigate("/login");
        return;
      }
      console.log(prepaidpayment);
      if (!prepaidpayment) {
        setCOD(true);
        setIsModalOpen(true);
      }
      setIsModalOpen(true);
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  useEffect(() => {
    getData();
    // const interval = setInterval(() => {
    //   if (carouselRef.current) {
    //     carouselRef.current.next();
    //   }
    // }, 3000); // Change slide every 3 seconds

    // return () => clearInterval(interval);
  }, [slug]);
  return (
    <>
      <Row
        align="space-between"
        style={{
          margin: "50px 15px 5px 15px",
          width: "100%",
          gap: "5px",
        }}
        gutter={[16, 16]}
      >
        <Col xs={23} sm={10}>
          <Carousel
            ref={carouselRef}
            afterChange={handleCarouselChange}
            dotPosition="bottom"
            autoplay
            autoplaySpeed={3000}
            style={{ marginBottom: "4px" }}
          >
            {bannerImage.map((img, index) => (
              <div key={index}>
                <Image
                  src={`${process.env.REACT_APP_AWS_S3_URL}/${img.path}`}
                  style={{ width: "100%" }}
                />
              </div>
            ))}
          </Carousel>
          <div
            style={{
              display: "flex",
              gap: "2px",
            }}
          >
            {bannerImage
              ?.filter((data) => data.path !== currentImage)
              .map((data, ind) => {
                return (
                  <div
                    key={ind}
                    style={{
                      width: "20%",
                      height: "20%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => handleImageClick(ind + 1)}
                  >
                    <img
                      src={`${process.env.REACT_APP_AWS_S3_URL}/${data?.path}`}
                      style={{
                        objectFit: "fill",
                        height: "80%",
                        width: "80%",
                      }}
                    ></img>
                  </div>
                );
              })}
          </div>
        </Col>
        <Col xs={23} sm={12}>
          <h1>{product?.name}</h1>
          <span
            style={{
              fontSize: "27px",
              fontWeight: "500",
            }}
          >
            ₹{product?.discount ? product?.discount : product?.price}
          </span>
          <span
            style={{
              color: "#919191",
              fontWeight: "500",
              textTransform: "uppercase",
              fontSize: "27px",
              textDecoration: "line-through",
              paddingLeft: "10px",
            }}
          >
            ₹{product.price >= 0 ? product.price : 0}
          </span>
          {product?.description?.map((data) => {
            return (
              <div style={{ textWrap: "wrap", padding: "4px" }}>
                <li>{data}</li>
              </div>
            );
          })}
          <div
            style={{ width: "100%", border: "1px solid  rgb(198 156 156)" }}
          ></div>
          <p
            style={{
              color: "#008000",
              fontWeight: "500",
              textTransform: "uppercase",
              fontSize: "15px",
            }}
          >
            {product?.stock} in stock
          </p>
          {product?.about_item?.length > 0 ? (
            <>
              <h3>About Item</h3>
              {product?.about_item?.map((data) => {
                return (
                  <div style={{ textWrap: "wrap", padding: "4px" }}>
                    <li>{data}</li>
                  </div>
                );
              })}
            </>
          ) : (
            ""
          )}
          <h3
            style={{
              fontWeight: "600",
              textTransform: "uppercase",
            }}
          >
            Brand : CRAFTY HEAVEN
          </h3>

          <div style={{ display: "flex", gap: "10px" }}>
            <Button type="primary" onClick={() => handlePayment(true)}>
              Buy Now
            </Button>
            {addtocart ? (
              <Button type="primary" onClick={() => handleAddToCart(true)}>
                Add to Cart
              </Button>
            ) : (
              <Button type="primary" onClick={() => handleAddToCart(false)}>
                Remove from Cart
              </Button>
            )}
            {/* <Button type="primary" onClick={() => handlePayment(false)}>
              Cash On Delivery
            </Button> */}
          </div>
        </Col>
      </Row>
      <Row align="space-evenly" style={{ margin: "60px 0 60px 0" }}>
        <Col xs={22} sm={10}>
          <div>
            <h3 style={{ textAlign: "center" }}>
              Please Leave Your Valuable Feedback
            </h3>
          </div>
          <Form onFinish={onFinish}>
            <Form.Item
              label="Rating"
              name="rating"
              rules={[
                {
                  required: true,
                  message: "Please give us your Feedback!",
                },
              ]}
            >
              <Rate
                onChange={(val) => {
                  setProdRating(val);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Comment"
              name="comment"
              rules={[
                {
                  required: true,
                  message: "Please give us your Feedback!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={22} sm={10}>
          {productreview?.length > 0 ? (
            <div>
              <h3 style={{ textAlign: "center" }}>Our Customer Rating</h3>
            </div>
          ) : (
            ""
          )}
          {productreview?.length > 0 ? (
            productreview?.map((prod, ind) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    key={ind}
                    style={{
                      width: "100%",
                      display: "flex",
                      margin: "5px",
                      gap: "5px",
                    }}
                  >
                    <div style={{ width: "8%" }}>
                      <Avatar size="medium" icon={<UserOutlined />} />
                    </div>
                    <div
                      style={{
                        width: "80%",
                        border: "1px solid #d9d9ed",
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        justifyContent: "space-around",
                        padding: "5px",
                      }}
                    >
                      <div>{getStars(prod?.rating)}</div>
                      <div>{prod?.user_id?.first_name}</div>
                      <div>{prod?.comment}</div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div>
                <h3 style={{ textAlign: "center" }}>Our Customer Rating</h3>
              </div>
              <div>
                <h4 style={{ textAlign: "center" }}>
                  Be the First One to give us Feedback
                </h4>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row align="center" style={{ margin: "20px 10px" }}>
        <div
          className="slidercust"
          style={{ height: "fit-content", width: "100%" }}
        >
          <div
            className="sliderproductshow"
            style={{
              overflow: "hidden",
              height: "fit-content",
              width: "100%",
              position: "relative",
            }}
          >
            <Slider
              dots={false}
              speed={500}
              autoplay={true}
              autoPlaySpeed={3000}
              slidesToScroll={1}
              infinite={true}
              responsive={[
                {
                  breakpoint: 3750,
                  settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 1750,
                  settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 825,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 580,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 300,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
              nextArrow={<NextArrow />}
              prevArrow={<PrevArrow />}
            >
              {relatedProduct?.map((item, index) => {
                return (
                  <ImageCard
                    key={index}
                    customStyle={{
                      border: "2px solid #808080",
                      borderRadius: "5px",
                      // maxWidth: "250px",
                    }}
                    name={item?.name}
                    imageLink={`${process.env.REACT_APP_AWS_S3_URL}/${item?.logo?.path}`}
                    slug={item?.slug}
                    price={item?.price}
                    discountedprice={
                      item?.discount ? item?.discount : item?.price
                    }
                    rating={item?.rating}
                  />
                );
              })}
            </Slider>
          </div>
        </div>
      </Row>
      <Modal
        title="Ship To"
        open={isModalOpen}
        onOk={false}
        destroyOnClose
        footer={[
          <Button type="primary" htmlType="submit" form="createForm" block>
            Submit
          </Button>,
        ]}
        onCancel={handleCancel}
      >
        <Form
          id="createForm"
          initialValues={{
            remember: true,
          }}
          onFinish={onChangeAddress}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Mobile"
            name="shipping_address.mobile"
            rules={[
              {
                required: true,
                message: "Please input Mobile Number",
              },
              {
                pattern: /^\d{10}$/,
                message: "Mobile number must be exactly 10 digits",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="State"
            name="shipping_address.state"
            rules={[
              {
                required: true,
                message: "Please input State",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="City"
            name="shipping_address.city"
            rules={[
              {
                required: true,
                message: "Please input State",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Address/Flat No:"
            name="shipping_address.street"
            rules={[
              {
                required: true,
                message: "Please input Street",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Pincode"
            name="shipping_address.pincode"
            rules={[
              {
                required: true,
                message: "Please input pincode",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
