import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import "./App.css";
import { useCookies } from "react-cookie";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./home";
import UserForm from "./UserForm";
import Login from "./login";
import CategoryForm from "./categoryForm";
import SubCategoryForm from "./SubCategoryForm";
import ProductForm from "./productForm";
import ProductShow from "./ProductShow";
import Shop from "./Shop";
import Aboutus from "./aboutus";
import Refund from "./refund.js";
import ProductList from "./productlist.js";
import Cart from "./Cart.js";
import Tandc from "./tandc";
import EditProduct from "./components/EditProduct.js";
import { FaWhatsapp } from "react-icons/fa";
import Order from "./order.js";
export function LayoutWrapper({ children }) {
  const { slug } = useParams();
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Header />
      {children}
      {slug ? (
        <div
          style={{
            width: "100px",
            height: "50px",
            position: "fixed",
            bottom: "14px",
            right: "30px",
            cursor: "pointer",
            textDecoration: "none",
            color: "green",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-12px",
              right: "2px",
              cursor: "pointer",
              textDecoration: "none",
              fontWeight: "700",
              color: "green",
            }}
          >
            Contact Us
          </div>
          <a
            style={{
              width: "50px",
              height: "50px",
              position: "fixed",
              cursor: "pointer",
              textDecoration: "none",
              color: "green",
            }}
            target="_blank"
            href={`https://api.whatsapp.com/send?phone=919810706191&text=Hello I need help with my order%20${slug}`}
          >
            <FaWhatsapp style={{ height: "100%", width: "100%" }}></FaWhatsapp>
          </a>
        </div>
      ) : (
        <div
          style={{
            width: "100px",
            height: "50px",
            position: "fixed",
            bottom: "14px",
            right: "30px",
            cursor: "pointer",
            textDecoration: "none",
            color: "green",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-12px",
              right: "2px",
              cursor: "pointer",
              textDecoration: "none",
              fontWeight: "700",
              color: "green",
            }}
          >
            Contact Us
          </div>
          <a
            style={{
              width: "50px",
              height: "50px",
              position: "fixed",
              cursor: "pointer",
              textDecoration: "none",
              color: "green",
            }}
            target="_blank"
            href={`https://api.whatsapp.com/send?phone=919810706191&text=Hello%2C%20I%20need%20help%20with%20my%20order`}
          >
            <FaWhatsapp style={{ height: "100%", width: "100%" }}></FaWhatsapp>
          </a>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Footer></Footer>
      </div>
    </div>
  );
}
function App() {
  const navigate = useNavigate();
  const [cookie] = useCookies(["userInfo"]);
  const location = useLocation();
  return (
    <div>
      <Routes>
        <Route
          exact
          path="/login"
          element={
            <LayoutWrapper>
              <Login />
            </LayoutWrapper>
          }
        />
        <Route
          exact
          path="/product/:slug"
          element={
            <LayoutWrapper>
              <ProductShow />
            </LayoutWrapper>
          }
        />
        <Route
          exact
          path="/"
          element={
            <LayoutWrapper>
              <Home></Home>
            </LayoutWrapper>
          }
        />
        <Route
          exact
          path="/cart"
          element={
            <LayoutWrapper>
              <Cart></Cart>
            </LayoutWrapper>
          }
        />
        <Route
          exact
          path="/productlist"
          element={
            <LayoutWrapper>
              <ProductList></ProductList>
            </LayoutWrapper>
          }
        />
        <Route
          exact
          path="/user/add"
          element={
            <LayoutWrapper>
              <UserForm></UserForm>
            </LayoutWrapper>
          }
        />
        <Route
          exact
          path="/category/add"
          element={
            <LayoutWrapper>
              <CategoryForm></CategoryForm>
            </LayoutWrapper>
          }
        />
        <Route
          exact
          path="/order"
          element={
            <LayoutWrapper>
              <Order />
            </LayoutWrapper>
          }
        />
        <Route
          exact
          path="/subcategory/add"
          element={
            <LayoutWrapper>
              <SubCategoryForm></SubCategoryForm>
            </LayoutWrapper>
          }
        />
        <Route
          exact
          path="/product/add"
          element={
            <LayoutWrapper>
              <ProductForm></ProductForm>
            </LayoutWrapper>
          }
        />
        <Route
          exact
          path="/product/edit/:id"
          element={
            <LayoutWrapper>
              <EditProduct />
            </LayoutWrapper>
          }
        />
        <Route
          exact
          path="/shop/category"
          element={
            <LayoutWrapper>
              <Shop></Shop>
            </LayoutWrapper>
          }
        />
        <Route
          exact
          path="/aboutus"
          element={
            <LayoutWrapper>
              <Aboutus></Aboutus>
            </LayoutWrapper>
          }
        />
        <Route
          exact
          path="/refund"
          element={
            <LayoutWrapper>
              <Refund></Refund>
            </LayoutWrapper>
          }
        />
        <Route
          exact
          path="/termsandcondition"
          element={
            <LayoutWrapper>
              <Tandc></Tandc>
            </LayoutWrapper>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
