import React from "react";
import { Flex, Layout } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
const { Footer } = Layout;
const AppFooter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isCurrentPageCategory = location.pathname === "/shop/category";
  const isCurrentPageCart = location.pathname === "/cart";
  return (
    <Footer
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        width: "100%",
        minWidth: "fit-content",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <h3>Our Offerings</h3>
        {!isCurrentPageCategory ? (
          <a
            href="/shop/category"
            style={{
              cursor: "pointer",
              fontSize: "14px",
              color: "#00493a",
              display: "block",
            }}
          >
            Our Products
          </a>
        ) : (
          ""
        )}
        {!isCurrentPageCart ? (
          <a
            href="/cart"
            style={{
              cursor: "pointer",
              fontSize: "14px",
              color: "#00493a",
              display: "block",
            }}
          >
            Cart Items
          </a>
        ) : (
          ""
        )}
      </div>
      <div
        className="knowmore"
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <h3>Terms of Service</h3>

        <a
          href="/termsandcondition"
          style={{
            cursor: "pointer",
            fontSize: "14px",
            color: "#00493a",
            display: "block",
          }}
        >
          Terms And Conditions
        </a>
        <a
          href="/aboutus"
          style={{
            cursor: "pointer",
            fontSize: "14px",
            color: "#00493a",
            display: "block",
          }}
        >
          About Us
        </a>
        <a
          href="/refund"
          style={{
            cursor: "pointer",
            fontSize: "14px",
            color: "#00493a",
            display: "block",
          }}
        >
          Refund Policy
        </a>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <h3> Packaged and Marketed By</h3>
        <p
          style={{
            cursor: "pointer",
            fontSize: "14px",
            color: "#00493a",
            display: "block",
            margin: 0,
          }}
        >
          <strong>Crafty Heaven</strong>
        </p>
        <div
          style={{
            display: "flex",
            justify: "center",
            gap: "6px",
            alignItems: "center",
          }}
        >
          <a
            href="https://api.whatsapp.com/send?phone=919810706191&text=Hello%2C%20I%20need%20help%20with%20my%20order"
            target="_blank"
            style={{
              cursor: "pointer",
              fontSize: "14px",
              color: "#00493a",
              display: "flex",
              justify: "center",
              gap: "6px",
              alignItems: "center",
            }}
          >
            <FaWhatsapp style={{ height: "20px", width: "20px" }} /> 9810706191
          </a>
        </div>
        <div
          style={{
            display: "flex",
            justify: "center",
            gap: "6px",
            alignItems: "center",
          }}
        >
          <a
            href="mailto: info@craftyheaven.online"
            style={{
              cursor: "pointer",
              fontSize: "14px",
              color: "#00493a",
              display: "flex",
              justify: "center",
              gap: "6px",
              alignItems: "center",
            }}
          >
            <MdEmail style={{ height: "20px", width: "20px" }} />
            info@craftyheaven.online
          </a>
        </div>
        <div
          style={{
            display: "flex",
            justify: "center",
            gap: "6px",
            alignItems: "center",
          }}
        >
          <a
            href="tel:9810706191"
            style={{
              cursor: "pointer",
              fontSize: "14px",
              color: "#00493a",
              display: "flex",
              justify: "center",
              gap: "6px",
              alignItems: "center",
            }}
          >
            <FaPhone style={{ height: "18px", width: "18px" }} />
            9810706191
          </a>
        </div>
      </div>
    </Footer>
  );
};

export default AppFooter;
