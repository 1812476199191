let apiurl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEVELOPMENT
    : process.env.REACT_APP_API;
async function getUploadURL(directory, file, _fetch, isPrivate) {
  const data = {
    files: {
      name: file.name,
      type: file.type,
    },
    directory: directory,
    private: isPrivate,
  };
  let apiURL = `${apiurl}/api/v1/user/getsignedurl`;
  let value = await _fetch(apiURL, {
    method: "POST",
    body: data,
  });
  const res = await value.json();
  return res.response;
}

async function upload(file, url) {
  let options;
  let contentType = "";
  contentType = file.type;
  options = {
    method: "PUT",
    headers: {
      "Content-Type": contentType,
    },
    body: file,
  };
  let res = await fetch(url, options);
  return res;
}
async function createFile(url, _id, name, _fetch, resolve, reject) {
  try {
    let parsedUrl = new URL(url);
    let pathname = parsedUrl.pathname.slice(1);
    let uploadedRes = await _fetch(`${apiurl}/api/v1/photo/create`, {
      method: "POST",
      body: {
        path: pathname,
        variant_id: _id,
        name: name,
      },
    });
    uploadedRes = await uploadedRes.json();
    if (uploadedRes.success) {
      if (!resolve) {
        return uploadedRes.response._id;
      }
      resolve(uploadedRes.response._id);
    } else {
      console.log("upload Details not uploaded");
    }
  } catch (e) {
    console.error(e);
    reject(e);
  }
}
async function uploadFile(
  file,
  _id,
  name,
  _fetch,
  isPrivate = false,
  resolve,
  reject
) {
  try {
    if (!file || !_id) {
      throw new Error("No file or directory");
    }
    const url = await getUploadURL(_id, file, _fetch, isPrivate);
    const res = await upload(file, url);
    if (res.status === 200) {
      await createFile(url, _id, name, _fetch, resolve, reject);
    }
  } catch (error) {
    return error;
  }
}

export { uploadFile };
