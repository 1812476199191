import React, { useState, useEffect } from "react";
import "../css/card.css";
import { Button } from "antd";
import { FaStar, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
export default function ImageCard({
  imageLink = "https://res.cloudinary.com/dhzouknuj/image/upload/c_fill,h_500,w_500/coc",
  name = "",
  rating = 5,
  price = 100,
  discountedprice,
  customStyle = undefined,
  slug = "",
  dontshowBuyButton = false,
}) {
  function GetStar({ rating = 5 }) {
    rating = Math.min(rating, 5);
    let arr = [];
    for (let i = 0; i < rating; i++) {
      arr.push(<AiFillStar style={{ color: "#ffc107" }} />);
    }

    return arr;
  }
  // useEffect(() => {
  //   console.log("s");
  // });
  const navigate = useNavigate();
  let parentstyle =
    customStyle !== undefined
      ? customStyle
      : {
          border: "2px solid #808080",
          borderRadius: "5px",
          // width: "fit-content",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        };

  return (
    <div
      className="flex flex-col justify-between card"
      style={{ ...parentstyle, height: "99%" }}
      onClick={() => {
        navigate(`/product/${slug}`);
        window.location.reload();
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }}
    >
      <div style={{ height: "180px", width: "100%" }}>
        <img
          src={imageLink}
          style={{ height: "100%", width: "100%", objectFit: "contain" }}
        ></img>
      </div>
      <div
        style={{
          margin: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: "2px",
          height: "54%",
        }}
      >
        <div>
          <p
            style={{
              color: "#919191",
              fontWeight: "500",
              textTransform: "uppercase",
              fontSize: "14px",
            }}
          >
            Crafty Heaven
          </p>
        </div>
        <div
          style={{
            maxHeight: "130px",
            height: "130px",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#2e2e2e",
            }}
          >
            {name.length > 50 ? name.substring(0, 50) + "..." : name}
          </p>
        </div>
        <div>
          <GetStar rating={rating}></GetStar>
        </div>
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#2e2e2e",
            lineHeight: "0",
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#2e2e2e",
              lineHeight: "0",
              display: "inline-block",
            }}
          >
            ₹{discountedprice}
          </p>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "rgb(145, 145, 145)",
              lineHeight: "0",
              textDecoration: "line-through",
            }}
          >
            ₹{price}
          </p>
        </div>
        {!dontshowBuyButton ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5px",
            }}
          >
            <Button type="primary" onClick={() => navigate(`/product/${slug}`)}>
              Buy Now
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
