import React, { useState, useEffect } from "react";
import { Button, Space, Table, Tag } from "antd";
import { _fetch } from "./_fetch";
import { SignInMethod } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

let apiurl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEVELOPMENT
    : process.env.REACT_APP_API;

export default function Order() {
  const columns = [
    {
      title: "Name",
      render: (_, record) => {
        return (
          <div
            onClick={() =>
              navigate(`/product/${record?.itemdata?.product?.slug}`)
            }
          >
            <a>{record?.itemdata?.product?.name}</a>
          </div>
        );
      },
    },
    {
      title: "Price Paid",
      render: (_, record) => {
        return (
          <div
            onClick={() =>
              navigate(`/product/${record?.itemdata?.amountpaidbycustomer}`)
            }
          >
            <a>{record?.itemdata?.amountpaidbycustomer}</a>
          </div>
        );
      },
    },
    {
      title: "Order Id",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={async () => {
              let res = await _fetch(`${apiurl}/api/v1/order/cancelorder`, {
                method: "POST",
                body: {
                  id: record?._id,
                  product_id: record?.itemdata?.product?._id,
                },
              });
              res = await res.json();
              if (res.success) {
                let newproduct = product.filter((data) => {
                  const itemDataMatch =
                    data?.itemdata?.product?._id?.toString() ===
                    record?.itemdata?.product?._id?.toString();
                  const idMatch =
                    data?._id?.toString() === record?._id?.toString();
                  return !(itemDataMatch && idMatch);
                });
                console.log(newproduct);
                setProduct(newproduct);
              }
            }}
          >
            Cancel
          </Button>
        </Space>
      ),
    },
  ];
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [cookie] = useCookies(["crafti&%373loggin"]);
  async function getData() {
    console.log(cookie["crafti&%373loggin"]);
    let userid = cookie["crafti&%373loggin"]?._id;
    let ordersofuser = await _fetch(
      `${apiurl}/api/v1/user/getorderofuser/${userid}`
    );
    ordersofuser = await ordersofuser.json();
    if (ordersofuser.success) {
      let finaldata = [];
      ordersofuser?.response?.map((data) => {
        data?.item?.map((itemdata) => {
          finaldata.push({ ...data, itemdata });
        });
      });
      setProduct(finaldata);
      console.log(finaldata);
    }
  }
  useEffect(() => {
    getData();
  }, []);
  return <Table columns={columns} dataSource={product} />;
}
