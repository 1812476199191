import React from "react";
import { Button, Checkbox, Flex, Form, Input, message } from "antd";
import { _fetch } from "./_fetch";
let apiurl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEVELOPMENT
    : process.env.REACT_APP_API;
const onFinish = async (values) => {
  try {
    let res = await _fetch(`${apiurl}/api/v1/category/create`, {
      method: "POST",
      body: values,
    });
    res = await res.json();
    if (res.success) {
      message.success("Category Created");
    } else {
      message.error("Something Went Wrong");
    }
  } catch (err) {
    console.error(err.message);
    message.error("Something Went Wrong");
  }
};
const onFinishFailed = (errorInfo) => {
  console.error("Failed:", errorInfo);
};
const UserForm = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      minHeight: "79vh",
    }}
  >
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      style={{ width: "50%" }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item
        label="Category Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please input your Category Name!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Slug"
        name="slug"
        rules={[
          {
            required: true,
            message: "Please input your Slug!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  </div>
);
export default UserForm;
