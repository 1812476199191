import React, { useState, useEffect, useSyncExternalStore } from "react";
import { Menu, Drawer, Button, Input } from "antd";
import { MenuFoldOutlined } from "@ant-design/icons";
import "../css/header.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
  HomeOutlined,
  UserOutlined,
  ShoppingCartOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { _fetch } from "../_fetch";
const logo = require("../assets/WhatsApp Image 2023-12-09 at 15.49.45_671565e5.jpg");
const { Search } = Input;

const TopNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  const [user, setUser] = useState();
  const isCurrentPage = location.pathname === "/shop/category";
  const [cookie] = useCookies();
  let apiurl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_DEVELOPMENT
      : process.env.REACT_APP_API;
  const onSearch = async (value, _e, info) => {
    let res = await _fetch(`${apiurl}/api/v1/product/filter`, {
      method: "POST",
      body: { tags: value },
    });
    res = await res.json();
  };

  async function getData() {
    try {
      let userid = cookie["crafti&%373loggin"]?._id;
      if (!userid) {
        return;
      }
      let res = await _fetch(`${apiurl}/api/v1/user/${userid}`);
      res = await res.json();
      setUser(res.response);
    } catch (err) {
      console.error(err);
    }
  }
  useEffect(() => {
    getData();
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
      if (!isSmallScreen) {
        setVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallScreen]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      {isSmallScreen ? (
        <div
          style={{
            margin: "5px 0 5px 0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <div
            class="wooden-text"
            style={{
              width: "80%",
              height: "60px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              style={{ height: "100%", objectFit: "contain" }}
            ></img>
            <p>CRAFTY HEAVEN</p>
          </div>
          <Button type="primary" onClick={showDrawer}>
            <MenuFoldOutlined />
          </Button>
        </div>
      ) : (
        <div
          style={{
            height: "10%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <div
            class="wooden-text"
            onClick={() => navigate("/")}
            style={{
              height: "50px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img
              src={logo}
              style={{ height: "100%", objectFit: "contain" }}
            ></img>
            <p>CRAFTY HEAVEN</p>
          </div>
          {/* <Search
            placeholder="input search text"
            allowClear
            onSearch={onSearch}
            style={{ width: "30%" }}
          /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              gap: "6px",
              alignItems: "center",
              width: "70%",
            }}
          >
            {!isCurrentPage ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "6px",
                  fontWeight: 500,
                  alignItems: "center",
                  width: "30%",
                }}
                className="headerlinks"
              >
                <div onClick={() => navigate(`/shop/category`)}>
                  <p style={{ fontWeight: 600 }}>Our Products</p>
                </div>
              </div>
            ) : (
              ""
            )}
            <div
              style={{ position: "relative", marginRight: "10%" }}
              className="headerlinks"
              onClick={() => navigate("/login")}
            >
              <p style={{ fontWeight: 600 }}>Login</p>
            </div>
            <div
              style={{ position: "relative", marginRight: "10%" }}
              className="headerlinks"
              onClick={() => navigate("/user/add")}
            >
              <p style={{ fontWeight: 600 }}>Sign Up</p>
            </div>
            <div
              style={{ position: "relative", marginRight: "10%" }}
              onClick={() => navigate("/cart")}
            >
              <ShoppingCartOutlined style={{ fontSize: "30px" }} />
              <span
                style={{
                  position: "absolute",
                  bottom: "65%",
                  right: "-29%",
                  color: "blueviolet",
                  fontWeight: "500",
                }}
              >
                {user?.cart?.length}
              </span>
            </div>
          </div>
        </div>
      )}
      <Drawer
        title="Navigation"
        placement="top"
        closable={true}
        onClose={onClose}
        visible={visible}
        style={{ height: "fit-content" }}
      >
        <Menu mode="vertical">
          <Menu.Item
            key="cart"
            onClick={() => navigate("/cart")}
            icon={<ShoppingCartOutlined />}
            style={{ fontWeight: 700 }}
          >
            Cart
          </Menu.Item>
          <Menu.Item
            key="cart"
            onClick={() => navigate("/shop/category")}
            // icon={<ShoppingCartOutlined />}
            style={{ fontWeight: 700 }}
          >
            Our Products
          </Menu.Item>
          <Menu.Item
            key="cart"
            onClick={() => navigate("/login")}
            // icon={<ShoppingCartOutlined />}
            style={{ fontWeight: 700 }}
          >
            Login
          </Menu.Item>
          <Menu.Item
            key="cart"
            onClick={() => navigate("/login")}
            // icon={<ShoppingCartOutlined />}
            style={{ fontWeight: 700 }}
          >
            Sign Up
          </Menu.Item>
        </Menu>
      </Drawer>
    </>
  );
};

export default TopNavigation;
